/* You can add global styles to this file, and also import other style files */
body {
    background-image: url("/assets/images/Background7.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0;
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    src: url("/assets/fonts/Roboto-Regular.ttf") format('truetype');
}

@font-face {
    font-family: "Roboto";
    font-style: Italic;
    font-weight: normal;
    src: url("/assets/fonts/Roboto-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    src: url("/assets/fonts/Roboto-Bold.ttf") format('truetype');
}
